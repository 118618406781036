import { Component, OnInit } from "@angular/core";
import { CustomerService } from "src/services/customer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmDialogComponent } from "src/app/confirm-dialog/confirm-dialog.component";
import { StorageService } from "src/services/storage.service";
import { Lightbox } from "ngx-lightbox";
import { UsersService } from "src/services/users.service";
import { DomSanitizer } from "@angular/platform-browser";
import { DocViewComponent } from "src/app/model-dialog/doc-view/doc-view.component";
import { environment } from "../../../../environments/environment";
import { QuoteStatus } from "src/models/comprehensive-quote";
import { user } from "@angular/fire/auth";
import { UpdateEnableFlagForUser } from "src/models/updateEnableFlagForUser";
import { take } from "rxjs/operators";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.css"],
})
export class UserDetailComponent implements OnInit {
  pageTitle = "All Users";
  albums: any = [];
  userData: any;
  error: any;
  pdfList = [];
  userDetail: any;

  serializedDOB: any = "";
  message: string;
  imagePath: any;
  // profileURL: string | ArrayBuffer;
  // profileThumbnailUrl: string;

  policyColumns: string[] = [
    "type",
    "policyNumber",
    "platNumber",
    "expiry",
    "viewPolicy",
  ];
  policyDataSource: any[];

  customersColumns: string[] = ["id", "customerName", "vehicles", "policies"];
  customersDataSource: any[];

  ordersColumns: string[] = [
    "orderNo",
    "plateNumber",
    "type",
    "policyNumber",
    "expiry",
    "status",
  ];
  ordersDataSource: any[];

  comprehensiveOrdersColumns: string[] = [
    "orderNo",
    "plateNumber",
    "type",
    "policyNumber",
    "expiry",
    "status",
  ];
  comprehensiveOrdersDataSource: any[];

  rcBooksDataSource: any[];
  rcBooksColumns: string[] = [
    "plateNumber",
    "purposeOfLicense",
    "chassisNumber",
    "carMake",
    "passengers",
    "modelYear",
    "expiry",
    "status",
  ];

  vehicleDataSource: any[];
  vehicleColumns: string[] = [
    "carMake",
    "carModel",
    "plateNumber",
    "chassisNumber",
    "modelYear",
    "marketValue",
  ];

  referees: any[];
  profileThumbnailUrl: string;

  elementType: "url" | "canvas" | "img" = "url";
  href: string;
  value: string;
  fileName: string;
  Safrattihref: string;
  Safrattivalue?: string;
  saiyartiLink: boolean;

  constructor(
    private customer: CustomerService,
    private route: ActivatedRoute,
    private diloag: MatDialog,
    private storageService: StorageService,
    private _lightBox: Lightbox,
    private userService: UsersService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private _snackbar: MatSnackBar
  ) {}

  preferenceForm = new FormGroup({
    isEnableZain: new FormControl<boolean>(false),
    isEnablePromocode: new FormControl<boolean>(false),
  });

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");

    this.value = window.location.origin + "/redirect/" + id;
    this.fileName = "qr.png";
    this.customer.getCustomer(id).subscribe(
      (userData: any) => {
        this.userData = userData;
        this.saiyartiLink = userData.isLinked;

        this.preferenceForm.patchValue({
          isEnableZain: userData.isEnableZain,
          isEnablePromocode: userData.isEnablePromocode,
        });
        if (!this.userData.civilCard) {
          this.userData.civilCard = {};
        }
        this.pageTitle = userData.firstName + " " + userData.lastName;
        this.Safrattivalue = `${window.location.origin}/safratti-redirect?id=${id}&name=${this.pageTitle}`;
        //this.Safrattivalue = `${environment.safrattiURL}/safratti-redirect?id=${id}&name=${this.pageTitle}`;
        this.fileName = userData.firstName + ".png";

        this.serializedDOB = new UntypedFormControl(
          new Date(this.userData.birthDate)
        );
        this.serializedDOB.disable();

        if (userData.civilCard !== undefined) {
          const usrProfileImageFront = userData.civilCard.id + "_front.jpg";
          const usrProfileImageBack = userData.civilCard.id + "_back.jpg";
          this.storageService
            .getSASTokenForContainer("civil-card")
            .subscribe((token) => {
              this.storageService
                .getSASTokenForContainer("thumbnail-civil-card")
                .subscribe((thumbToken) => {
                  const profileURLFront =
                    environment.cdnUrl +
                    "/civil-card/" +
                    usrProfileImageFront +
                    token.sasToken;
                  const profileURLBack =
                    environment.cdnUrl +
                    "/civil-card/" +
                    usrProfileImageBack +
                    token.sasToken;
                  this.profileThumbnailUrl =
                    environment.cdnUrl +
                    "/thumbnail-civil-card/" +
                    usrProfileImageFront +
                    thumbToken.sasToken;
                  const profileThumbnailUrlBack =
                    environment.cdnUrl +
                    "/thumbnail-civil-card/" +
                    usrProfileImageBack +
                    thumbToken.sasToken;
                  const album = {
                    src: profileURLFront,
                    caption: "Civil Card Front",
                    thumb: this.profileThumbnailUrl,
                  };
                  this.albums[0] = album;

                  const albumBack = {
                    src: profileURLBack,
                    caption: "Civil Card Back",
                    thumb: profileThumbnailUrlBack,
                  };
                  this.albums[1] = albumBack;
                });
            });
        }

        this.ordersDataSource = this.userData.orders;
        this.rcBooksDataSource = this.userData.rcBooks;
        this.customersDataSource = this.userData.customers;
        this.referees = this.userData.referees;
        this.vehicleDataSource = this.userData.comprehensiveVehicles;
        this.comprehensiveOrdersDataSource = this.userData.comprehensiveOrders;
      },
      (err) => {
        this.error = err;
      }
    );

    /**
     * To call list of purchase.
     */
    this.getPurchaseList(id, 0, 100);
  }
  openImage(index: number): void {
    this._lightBox.open(this.albums, index);
  }

  onProfileFileSelected(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      // this.profileThumbnailUrl = reader.result;
    };
  }

  blockCustomer() {
    const dialogRef = this.diloag.open(ConfirmDialogComponent, {
      data: {
        title: "Confirm",
        message: "Are you sure want to block customer?",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // TODO : logic for ok
      } else {
        // TODO : logi for cancel
      }
    });
  }

  getPurchaseList(userId: string, skip: number, limit: number) {
    this.userService.getPurchases(userId, skip, limit).subscribe(
      (res) => {
        console.log(res);
        this.policyDataSource = res;
      },
      (err) => {}
    );
  }

  openRCBook(row): void {
    this.router.navigate(["/rcbook/" + row.id]);
  }

  getPolicyPdf(data) {
    this.storageService
      .getSASToken(data.id + "_background.pdf", "policies")
      .subscribe(
        (token: any) => {
          const url =
            environment.cdnUrl +
            "/policies/" +
            data.id +
            "_background.pdf" +
            token.sasToken +
            "#toolbar=0";
          const pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.diloag.open(DocViewComponent, { data: { url: pdfUrl } });
        },
        (err) => {}
      );
  }

  policyEndDate(data) {
    const date = new Date(data.startDate);
    date.setDate(date.getDate() - 1);
    return date.setFullYear(
      date.getFullYear() + (data.tpl === undefined ? 0 : data.tpl.period)
    );
  }

  /** open customer detail */
  openCustomer(row): void {
    this.router.navigate(["/customers/" + row.id]);
  }

  openPurchaseDetail(row): void {
    if(row.type === "Safratti"){
      this.router.navigate(["/safratti-orders/" + row.id],{
        state: { policyIssuer: row.issuer },
      });
    } else if (row.status === "PENDING" || row.status === "REQUESTRESENT") {
      this.router.navigate(["/consumer-request/purchase-detail/" + row.id]);
    } else if (row.status === "REQUESTRESENT") {
    } else if (row.status === "DISPATCHED" || row.status === "DELIVERED") {
      this.router.navigate(["/orders/" + row.id]);
    } else {
      this.router.navigate(["/consumer-request/policy-status/" + row.id]);
    }
  }

  openComprehensivePurchaseDetail(row): void {
    if (row.status === "PENDING") {
      this.router.navigate([
        "/comprehensive-consumer-request/cr-detail/" + row.rateCardId,
      ]);
    } else if (
      row.status === QuoteStatus.DELIVERED ||
      row.status === QuoteStatus.DISPATCHED
    ) {
      this.router.navigate(["/comprehensive-orders/" + row.rateCardId]);
    } else {
      this.router.navigate([
        "/comprehensive-consumer-request/policy-status/" + row.rateCardId,
      ]);
    }
  }
  downloadImage() {
    console.log("Downloading Image");
    this.href = document
      .getElementById("qrCodeImage")
      .getElementsByTagName("img")[0].src;
  }
  downloadSafrattiImage() {
    console.log("Downloading Safratti Image");
    this.Safrattihref = document
      .getElementById("qrSafrattiCodeImage")
      .getElementsByTagName("img")[0].src;
  }

  updateEnableFlagForUser() {
    const id = this.route.snapshot.paramMap.get("id");
    const isEnableZain = this.preferenceForm.get("isEnableZain").value;
    const isEnablePromocode =
      this.preferenceForm.get("isEnablePromocode").value;
    const updateFlag: UpdateEnableFlagForUser = {
      isEnableZain,
      isEnablePromocode,
    };
    this.customer.getEnableFlagForUser(id, updateFlag).subscribe((x) => {
      this._snackbar.open("Preferences Updated Successfully.", "Close", {
        duration: 3000,
      });
    });
  }
}
